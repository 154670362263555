/**
 *
 * You need 2 elements nested under theParent for this to work.
 *
 * An element that is shown when the APP isn't busy, normally a save, update
 * submit form button.  Give this element a class of "saveAndPublishButton"
 *
 * An element that tells the user the APP is busy, normally the PWI
 * loading icon.  Give this element a class of "saveAndPublishBusy"
 *
 * showBusyAnimation(theParent) will find the nested elements and toggle them
 * alerting the user that the APP is busy. *
 *
 * @param theParent
 */
window.showBusyAnimation=function(theParent) {
    $(theParent).find(".saveAndPublishButton").addClass("hidden");
    $(theParent).find(".cancelSaveAndPublishButton").addClass("hidden");

    $(theParent).find(".saveAndPublishBusy").removeClass("hidden");
}

/**
 *
 * You need 2 elements nested under theParent for this to work.
 *
 * An element that is shown when the APP isn't busy, normally a save, update
 * submit form button.  Give this element a class of "saveAndPublishButton"
 *
 * An element that tells the user the APP is busy, normally the PWI
 * loading icon.  Give this element a class of "saveAndPublishBusy"
 *
 * hideBusyAnimation(theParent) will find the nested elements and toggle them
 * alerting the user that the APP is NOT busy. *
 *
 * @param theParent
 */
window.hideBusyAnimation=function(theParent) {
    $(theParent).find(".saveAndPublishButton").removeClass("hidden");
    $(theParent).find(".cancelSaveAndPublishButton").removeClass("hidden");

    $(theParent).find(".saveAndPublishBusy").addClass("hidden");
}

window.menuBind=function() {
    $(".navMenuItem").on("click", function () {



        $(".navMenuItem").removeClass("active");
        $(this).addClass("active");
        var conferenceCode = $("#currentConferenceCode").val();

        var loadThisPage = $(this).data("load-name");

        window.location.hash = loadThisPage;

        var path = "/module/convenePortal/" + conferenceCode + "/getPage/" + loadThisPage;

        $(".convenePortalRightSideContainer").html('<div class="text-align-center"><img src="https://res.cloudinary.com/project-world-impact/image/upload/c_scale,w_100/v1687465593/system/loading2.gif" alt="Loading..."></div>');
        $(".convenePortalRightSideContainer").load(path, function (response, status, xhr) {
            if (status === "success") {

                $(".module-header").each(function () {
                    reloadModule(this);
                });

            }

            if (status === "error") {
                $(".convenePortalRightSideContainer").html("<div class='text-align-center text-danger'><div><i class='fa fa-4x fa-exclamation-triangle' aria-hidden='true'></i></div><div>Something went wrong</div></div>");
            }
        });
    });
}

window.getPage=function(pageNameHash) {
    var loadThisPage = pageNameHash.replace("#", "");
    var conferenceCode = $("#currentConferenceCode").val();
    var path = "/module/convenePortal/" + conferenceCode + "/getPage/" + loadThisPage;

    $(".navMenuItem").removeClass("active");
    $(".navMenuItem[data-load-name='"+loadThisPage+"']").addClass("active");

    $(".convenePortalRightSideContainer").html('<div class="text-align-center"><img src="https://res.cloudinary.com/project-world-impact/image/upload/c_scale,w_100/v1687465593/system/loading2.gif" alt="Loading..."></div>');
    $(".convenePortalRightSideContainer").load(path, function (response, status, xhr) {
        if (status === "success") {

            $(".module-header").each(function () {
                reloadModule(this);
            });

        }

        if (status === "error") {
            $(".convenePortalRightSideContainer").html("<div class='text-align-center text-danger'><div><i class='fa fa-4x fa-exclamation-triangle' aria-hidden='true'></i></div><div>Something went wrong</div></div>");
        }
    });
}

window.processAjaxData=function(response, urlPath){
    document.getElementById("content").innerHTML = response.html;
    document.title = response.pageTitle;
}